import React from 'react';
import ankit from "../../../static/images/users/ankit.jpg"
import rossi from "../../../static/images/users/rossi.jpg"
import sateesh from "../../../static/images/users/sateesh.jpg"
import jon from "../../../static/images/users/jon.jpg"
import art from "../../../static/images/users/art.jpg"
import matt from "../../../static/images/users/mattk.jpg"

// import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles((theme) => ({
//     navLink: {
//         fontWeight: "normal",
//         paddingRight: "20px",
//         textTransform: 'none',
//     },
//     linkDiv: {
//         paddingBottom: "20px"
//     }
// }));

const NewsletterFeedback = () => {
    // const classes = useStyles();
    return (
            <>
            <div className={"row"}>
                <div className={"col-6"} style={{ border: "1px solid #ececec" }}>
                    <div className="team team-summary team-summary-large">
                        <div className="team-image">
                            <img 
                                alt="Ankit Malhotra @ Instagram" 
                                className="img-fluid mb-2" 
                                src={ankit} />
                        </div>
                        <div className="team-meta">
                            <h2 className="team-name">Ankit Malhotra</h2>
                            <p className="team-description">Engineering Manager at <b>Instagram</b></p>
                        </div>
                        <p style={{ height: "100px", verticalAlign: "middle", paddingTop: "5%", paddingBottom:"5%"}}>
                            <i>“If you are a manager I highly recommend signing up for Managers Weekly. It has a simple format and gives you content about all aspects of managing a team and leadership.”</i>
                        </p>
                    </div>
                </div>
                <div className={"col-6"} style={{ border: "1px solid #ececec" }}>
                    <div className="team team-summary team-summary-large">
                        <div className="team-image">
                            <img 
                                alt="Rossi Reyes @ Dropbox" 
                                className="img-fluid mb-2" 
                                src={rossi} />
                        </div>
                        <div className="team-meta">
                            <h2 className="team-name">Rossi Reyes</h2>
                            <p className="team-description">Head Of Telemetry Engineering at <b>Dropbox</b></p>
                        </div>
                        <p style={{ height: "100px", verticalAlign: "middle", paddingTop: "5%", paddingBottom:"5%"}}>
                            <i>“I have been a reader of Managers weekly for 3-4 weeks but it has quality curated content for managers and I have been enjoying the articles”</i>
                        </p>
                    </div>
                </div>
            </div>
            <div className={"row"}>
                {/* <Subscribe title="" buttonText="Subscribe" /> */}
                <br /><br />
            </div>
            <div className={"row"}>
                <div className={"col-6"} style={{ border: "1px solid #ececec" }}>
                    <div className="team team-summary team-summary-large">
                        <div className="team-image">
                            <img 
                                alt="Arthur Fogiel @ TA Instruments" 
                                className="img-fluid mb-2" 
                                src={art} />
                        </div>
                        <div className="team-meta">
                            <h2 className="team-name">Arthur Fogiel</h2>
                            <p className="team-description">Software Manager at <b>TA Instruments</b></p>
                        </div>
                        <p style={{ height: "100px", verticalAlign: "middle", paddingTop: "5%", paddingBottom:"5%"}}>
                            <i>“Managers weekly curates content that gives me new ideas and helps me look at management from different points of view”</i></p>
                    </div>
                </div>
                <div className={"col-6"} style={{ border: "1px solid #ececec" }}>
                    <div className="team team-summary team-summary-large">
                        <div className="team-image">
                            <img alt="Sateesh Lakkarsu @ Google" className="img-fluid mb-2" src={sateesh} />
                        </div>
                        <div className="team-meta">
                            <h2 className="team-name">Sateesh Lakkarsu</h2>
                            <p className="team-description">Engineering Manager <b>Google</b></p>
                        </div>
                        <p style={{ height: "100px", verticalAlign: "middle", paddingTop: "5%", paddingBottom:"5%"}}>
                            <i>“Good content about people management, team culture and leadership delivered in a simple format.  I recommend Managers Weekly to anyone who is interested in these areas”</i>
                        </p>
                    </div>
                </div>
                {/* <div className={"col-6"} style={{ border: "1px solid #ececec" }}>
                    <div className="team team-summary team-summary-large">
                        <div className="team-image">
                            <img alt="" className="img-fluid mb-2" src="https://media-exp1.licdn.com/dms/image/C4E03AQGxNIxUDziKVw/profile-displayphoto-shrink_400_400/0/1552926145541?e=1614211200&v=beta&t=F8JKdRGGM5WET8ljMNjHoV0MzHxK6Re0W_9EpdDvAY4" />
                        </div>
                        <div className="team-meta">
                            <h2 className="team-name">Jonathan Collette</h2>
                            <p className="team-description">Engineering Lead at <b>Cedar</b></p>
                        </div>
                        <p>
                            <br />
                            <i>“I find the Managers Weekly content relevant and useful. I recommend it to anyone who manages technical talent”</i>
                            <br /><br />
                        </p>
                    </div>
                </div> */}
            </div>
            <div className={"row"}>
                <div className={"col-6"} style={{ border: "1px solid #ececec" }}>
                    <div className="team team-summary team-summary-large">
                        <div className="team-image">
                            <img alt="Matt Kendall @ Blockthrough" className="img-fluid mb-2" src={matt} />
                        </div>
                        <div className="team-meta">
                            <h2 className="team-name">Matt Kendall</h2>
                            <p className="team-description">Head Of Engineering at <b>Blockthrough</b></p>
                        </div>
                        <p style={{ height: "100px", verticalAlign: "middle", paddingTop: "5%", paddingBottom:"5%"}}>
                            <i>"Managers Weekly consistently delivers high quality content that I find very valuable to myself and team. I recommend it to anyone who is interested in leveling up in their leadership capabilities"</i>
                        </p>
                    </div>
                </div>
                <div className={"col-6"} style={{ border: "1px solid #ececec" }}>
                    <div className="team team-summary team-summary-large">
                        <div className="team-image">
                            <img alt="Jonathan Collette" className="img-fluid mb-2" src={jon} />
                        </div>
                        <div className="team-meta">
                            <h2 className="team-name">Jonathan Collette</h2>
                            <p className="team-description">Engineering Lead at <b>Cedar</b></p>
                        </div>
                        <p style={{ height: "100px", verticalAlign: "middle", paddingTop: "5%", paddingBottom:"5%"}}>
                            <i>“I find the Managers Weekly content relevant and useful. I recommend it to anyone who manages technical talent”</i>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NewsletterFeedback